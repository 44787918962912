<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Counties</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'permitCountyForm', params: {id: 0} }"
                        >
                            New Permit County
                        </v-btn>
                    </template>
                    <span>New Permit County</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'permitCountyForm'"
                            :showButtons="{
                                edit: true,
                                cancel: item.status === 1 ? true : false,
                                active: item.status === 0 ? true : false
                            }"
                            @confirmCancel="confirmCancel"
                            @confirmActive="confirmActive"   />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Name", value: "name", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listPermitCounty: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listPermitCounty.filter((templateFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var dateRegisterFormatted = templateFilter.dateRegisterFormatted.toLowerCase().match(filter)
                    var name = templateFilter.name.toLowerCase().match(filter)
                    var statusDescription = templateFilter.statusDescription.toLowerCase().match(filter)
                    var id = templateFilter.id.toString().match(filter);

                    if(dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else if(name != null) { return name; } 
                    else if(statusDescription != null) { return statusDescription; } 
                    else { return id; }
                });
            }
        },

        methods: {
            
            async getRegisters() {

                this.listPermitCounty = await this.$store.dispatch("permitCountyModule/List", false);
            },

            confirmCancel(id) {

                this.statusId = 0;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will INACTIVATE this County, confirm your decision?',
                    methodConfirm: this.cancel,
                    params: id
                };
            },

            async cancel(id) {

                let statusId = this.statusId;

                this.showLoading();

                let result = await this.$store.dispatch("permitCountyModule/UpdateStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmActive(id) {

                this.statusId = 1;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will ACTIVATE this County, confirm your decision?',
                    methodConfirm: this.active,
                    params: id
                };

            },

            async active(id) {

                this.showLoading();
                let statusId = this.statusId;

                let result = await this.$store.dispatch("permitCountyModule/UpdateStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            }

        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>
